import {Inject, Injectable} from '@angular/core';
import {OAuthProvider} from 'firebase/auth';
import {switchMap, tap} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {UserService} from './user.service';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';
import firebase from 'firebase/compat';
import User = firebase.User;
import {ErrorService} from './error.service';
import {LocalStorageKeys} from '../constants';

@Injectable({
  providedIn: 'root'
})
export class AuthUserService extends AuthService {
  photoUrl: string | null;

  constructor(afs: AngularFirestore,
              afAuth: AngularFireAuth,
              userService: UserService,
              http: HttpClient,
              errorService: ErrorService,
              router: Router,
              @Inject('env') env: any) {
    super(afs, afAuth, userService, http, router, errorService, env);
    if (!!localStorage['gsi-token']) {
      this.userService.retrieveGoogleUserInfo().subscribe(({picture}) => (this.photoUrl = picture));
    } else {
      this.afAuth.authState.subscribe((user: User | null) => {
        if (user) {
          this.photoUrl = user.photoURL;
        }
      });
    }
  }

  async signInWithGoogle(authorizationCode: string) {
    this.userService
      .userGoogleSignIn(authorizationCode)
      .pipe(
        tap((token) => localStorage.setItem(LocalStorageKeys.GoogleToken, token)),
        switchMap(() => this.userService.retrieveGoogleUserInfo()),
        tap(({picture}) => (this.photoUrl = picture))
      )
      .subscribe({
        next: async () => {
          await this.router.navigate(['/', 'sign-in', 'user', 'salesforce']);
        },
        error: async (e: HttpErrorResponse) => {
          this.handleAODocsGoogleAuthError(e.error);
        },
      });
  }

  async signInWithMicrosoft(domain: string) {
    try {
      await this.afAuth.signInWithPopup(new OAuthProvider('microsoft.com').setCustomParameters({prompt: 'select_account'}));
      await this.router.navigate(this.isSignedInSalesforce() ? ['migration-rules'] : ['/', 'sign-in', 'user', 'salesforce']);
    } catch (error: any) {
      this.authErrorMessage$.next(error.error);
      await this.afAuth.signOut();
      this.errorService.refreshAuthComponent();
    }
  }

  retrieveGoogleUserInfo() {
    if (!!localStorage[LocalStorageKeys.GoogleToken]) {
      this.userService.retrieveGoogleUserInfo().subscribe(({picture}) => (this.photoUrl = picture));
    }
  }

  isSignedInSalesforce(): boolean {
    return !!localStorage['sf-token'];
  }

  async handleAODocsTokenExpired(error?: string): Promise<void> {
    if (error) {
      this.authErrorMessage$.next(error);
    }
    localStorage.removeItem(LocalStorageKeys.GoogleToken);
    await this.afAuth.signOut();
    await this.router.navigate(['/', 'sign-in', 'user', 'aodocs']);
  }
}
